import { useGenerateCalendar } from 'hooks/useGenerateCalendar';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';

export function Success() {
  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get('session_id');

  const generateCalendar = useGenerateCalendar();
  const onGenerate = useCallback((): void => {
    if (!checkoutSessionId) {
      return;
    }

    generateCalendar.mutateAsync(checkoutSessionId).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'build-a-planner.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }, [checkoutSessionId, generateCalendar]);

  return (
    <div className="flex flex-col items-center">
      <header className="flex flex-col items-center justify-end text-center h-[50vh] gap-8 mb-8">
        <Logo className="h-16 w-auto" title="Build a Planner" />

        <h1 className="font-extrabold text-4xl uppercase">🎉 Thank you 🎉</h1>

        <p className="text-stone-500">
          Your order has been completed successfully! 🙌
          <br />
          You can now generate and download your planner.
        </p>
      </header>

      <button disabled={generateCalendar.isLoading} className="btn cta" onClick={onGenerate}>
        👉 Generate my Planner
      </button>

      {generateCalendar.isLoading && (
        <p className="mt-4 font-bold text-cta max-w-sm text-center">
          Generating your planner, this can take up to 2&nbsp;minutes...
        </p>
      )}

      {generateCalendar.isError && (
        <p className="mt-4 text-sm text-red-500 max-w-sm text-center">
          There was an error generating your planner. Please try again later or contact support.
        </p>
      )}

      <p className="mt-8 text-sm text-center text-stone-500">
        <b>IMPORTANT</b>
        <br />
        Please bookmark this page to access your planner in the future.
      </p>

      <p className="mt-8 text-sm text-center text-stone-500">
        If there are any issues with your order,
        <br />
        please contact{' '}
        <a className="text-sky-600 cursor-pointer" href="mailto:admin@build-a-planner.com">
          admin@build-a-planner.com
        </a>
        .
      </p>
    </div>
  );
}
