import { useMutation } from 'react-query';
import { BASE_URL } from './constants';
import { CalendarConfig } from './types';

interface CheckoutResponse {
  checkoutUrl: string;
}

export function useCheckout() {
  return useMutation(fetchCheckout);
}

async function fetchCheckout(config: CalendarConfig): Promise<CheckoutResponse> {
  const response = await fetch(`${BASE_URL}/scripting/checkout.php`, {
    method: 'POST',
    body: JSON.stringify(config),
  });

  if (!response.ok) {
    console.error('failed to generate checkout details', response.status, await response.text());
    throw new Error('failed to generate checkout details');
  }

  return await response.json();
}
