import { QueryFunctionContext, useQuery, UseQueryResult } from 'react-query';
import { BASE_URL, IS_DEVELOPMENT } from './constants';
import { CalendarConfig } from './types';

export interface CalendarPreview {
  calendarLeft: string;
  calendarRight: string;
  notes: string;
  yearOverview: string;
  compactMonthlyTodos: string;
  extendedMonthlyTodos: string;
}

export function useCalendarPreview(
  params?: CalendarConfig
): UseQueryResult<CalendarPreview | undefined> {
  return useQuery(['preview', params], fetchPreview, {
    keepPreviousData: true,
    refetchInterval: IS_DEVELOPMENT ? 3000 : false,
  });
}

async function fetchPreview({
  signal,
  queryKey,
}: QueryFunctionContext<['preview', CalendarConfig | undefined]>): Promise<
  CalendarPreview | undefined
> {
  if (!queryKey[1]) {
    return undefined;
  }

  const response = await fetch(`${BASE_URL}/scripting/preview.php`, {
    method: 'POST',
    body: JSON.stringify(queryKey[1]),
    signal,
  });

  if (!response.ok) {
    console.error('failed to load preview', response.status, await response.text());
    throw new Error('failed to load preview');
  }

  const previewData = (await response.json()) as CalendarPreview;
  const cacheBreaker = IS_DEVELOPMENT ? `?${Date.now()}` : '';
  return {
    calendarLeft: `${BASE_URL}${previewData.calendarLeft}${cacheBreaker}`,
    calendarRight: `${BASE_URL}${previewData.calendarRight}${cacheBreaker}`,
    notes: `${BASE_URL}${previewData.notes}${cacheBreaker}`,
    yearOverview: `${BASE_URL}${previewData.yearOverview}${cacheBreaker}`,
    compactMonthlyTodos: `${BASE_URL}${previewData.compactMonthlyTodos}${cacheBreaker}`,
    extendedMonthlyTodos: `${BASE_URL}${previewData.extendedMonthlyTodos}${cacheBreaker}`,
  };
}
