import classNames from 'classnames';
import { Toggle } from 'components/Toggle';
import { CalendarPreview } from 'hooks/useCalendarPreview';
import { useEffect, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import sharedStyles from './Configurator.shared.module.less';
import { FooterButtons } from './FooterButtons';
import { CALENDAR_ORIENTATION_COLUMN, ConfiguratorFormData, LOCALE_US } from './form';
import styles from './Layout.module.less';
import { StepComponentProps } from './types';

interface LayoutProps extends StepComponentProps {
  previewLoading: boolean;
  preview?: CalendarPreview;
  onUpdatePreview: (formData: ConfiguratorFormData) => void;
}

const COLUMN_OPTIONS: { value: 'three' | 'four'; label: string }[] = [
  { value: 'three', label: '3 Columns' },
  { value: 'four', label: '4 Columns' },
];

export function Layout({ onBack, onNext, previewLoading, preview, onUpdatePreview }: LayoutProps) {
  const [side, setSide] = useState<'left' | 'right'>('left');

  const form = useFormContext<ConfiguratorFormData>();
  const showCalendarWeek = useController({
    control: form.control,
    name: 'layout.showCalendarWeek',
    defaultValue: true,
  });
  const showWeekDates = useController({
    control: form.control,
    name: 'layout.showWeekDates',
    defaultValue: true,
  });
  const weeklyColumns = useController({
    control: form.control,
    name: 'layout.weeklyColumns',
    defaultValue: 'three',
  });
  const showBoxes = useController({
    control: form.control,
    name: 'layout.showBoxes',
    defaultValue: false,
  });
  const showAllDay = useController({
    control: form.control,
    name: 'layout.showAllDay',
    defaultValue: true,
  });
  const showNotes = useController({
    control: form.control,
    name: 'layout.showNotes',
    defaultValue: true,
  });
  const dottedBg = useController({
    control: form.control,
    name: 'layout.dottedBg',
    defaultValue: true,
  });

  const formValues = useWatch({
    control: form.control,
  });
  useEffect(() => {
    onUpdatePreview(formValues as ConfiguratorFormData);
  }, [formValues, onUpdatePreview]);

  const previewImgSrc = side === 'left' ? preview?.calendarLeft : preview?.calendarRight;

  return (
    <>
      <h1 className={sharedStyles.configurator__subtitle}>Step 3: Modify Calendar view</h1>

      <div className="flex flex-row gap-4 flex-wrap-reverse mt-8">
        <div className="flex flex-col items-start min-w-[300px]">
          <h2 className={classNames(sharedStyles.configurator__label, 'nomargin')}>Options</h2>

          <h3 className="font-semibold uppercase text-sm mt-6 mb-3">Header</h3>
          <div className="flex flex-col gap-3 text-sm">
            <Toggle label="Show calendar week" {...showCalendarWeek.field} />
            <Toggle label="Show week start and end" {...showWeekDates.field} />
          </div>

          <h3 className="font-semibold uppercase text-sm mt-6 mb-3">Days</h3>
          <div className="self-stretch flex flex-col gap-3 text-sm">
            {formValues.locale !== LOCALE_US &&
              formValues.calendarView === 'weekly' &&
              formValues.orientation === CALENDAR_ORIENTATION_COLUMN && (
                <div className="flex flex-row gap-2">
                  {COLUMN_OPTIONS.map((option) => (
                    <label
                      key={option.value}
                      className={classNames(
                        sharedStyles.configurator__option,
                        'flex-1 p-sm',
                        option.value === weeklyColumns.field.value && 'selected'
                      )}
                    >
                      {option.label}
                      <input
                        type="radio"
                        className="hidden"
                        {...weeklyColumns.field}
                        value={option.value}
                      />
                    </label>
                  ))}
                </div>
              )}
            {formValues.orientation === CALENDAR_ORIENTATION_COLUMN && (
              <>
                <Toggle label="Show All Day section" {...showAllDay.field} />
                <Toggle label="Show Notes section" {...showNotes.field} />
              </>
            )}
            <Toggle label="Draw bordered boxes" {...showBoxes.field} />
          </div>

          <h3 className="font-semibold uppercase text-sm mt-6 mb-3">Background</h3>
          <div className="self-stretch flex flex-col gap-3 text-sm">
            <Toggle label="Use dotted background" {...dottedBg.field} />
          </div>
        </div>

        <div className="flex flex-col flex-1 sm:min-w-[400px]">
          <div className="flex flex-row items-center">
            <h2 className={classNames(sharedStyles.configurator__label, 'nomargin')}>Preview</h2>
            <span className="flex-1"></span>
            <div>
              <button
                className={classNames(
                  sharedStyles.configurator__btn,
                  styles.layout__btnLeft,
                  'w-20 text-center',
                  side === 'left' && 'selected'
                )}
                onClick={() => setSide('left')}
                type="button"
              >
                ⬅️ Left
              </button>
              <button
                className={classNames(
                  sharedStyles.configurator__btn,
                  styles.layout__btnRight,
                  'w-20 text-center',
                  side === 'right' && 'selected'
                )}
                onClick={() => setSide('right')}
                type="button"
              >
                Right ➡️
              </button>
            </div>
          </div>
          <div className="bg-stone-100 p-4 mt-4 rounded-lg flex flex-col items-center relative min-h-[50px]">
            {previewLoading && (
              <div className="flex justify-center items-center absolute top-2 right-2">
                <span className="h-8 w-8 block rounded-full border-solid border-4 border-stone-400 border-t-stone-600 animate-spin"></span>
              </div>
            )}
            {!!preview && (
              <img className="max-h-[600px]" src={previewImgSrc} alt="Calendar preview" />
            )}
          </div>
        </div>
      </div>

      <FooterButtons nextEnabled={true} onBack={onBack} onNext={onNext} />
    </>
  );
}
