import { ReactComponent as Logo } from './assets/logo.svg';
import imprint from './assets/imprint.png';
import { Link } from 'react-router-dom';

export function Imprint() {
  return (
    <>
      <header className="flex flex-col items-center justify-end text-center gap-6">
        <a className="cursor-pointer" href="/">
          <Logo className="h-36 w-auto my-16" title="Build a Planner" />
        </a>
      </header>

      <main className="mx-auto w-[600px] flex flex-col gap-4 text-stone-800 px-4 pb-16">
        <h1 className="font-bold text-xl">Imprint</h1>
        <p>
          <img src={imprint} alt="Imprint" width={133} />
        </p>
        <p>
          E-Mail:{' '}
          <a className="cursor-pointer text-blue-600" href="mailto:admin@build-a-planner.com">
            admin@build-a-planner.com
          </a>
        </p>

        <h1 className="font-bold text-xl">Note</h1>
        <p>
          You can find the EU&apos;s platform for out-of-court dispute resolution online at:&nbsp;
          <a
            className="cursor-pointer text-blue-600"
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .
        </p>

        <Link to="/configure" className="cursor-pointer text-lg btn cta self-center mt-8">
          👉 Get my own Planner
        </Link>
      </main>
    </>
  );
}
