import { useCallback, useEffect, useRef } from 'react';

export function useDebounced<P extends unknown[]>(
  callback: (...args: P) => void,
  timeoutMs: number
): (...args: P) => void {
  const cb = useRef<(...args: P) => void>(callback);
  useEffect(() => {
    cb.current = callback;
  }, [callback]);

  const timeout = useRef(-1);
  return useCallback(
    (...args: P): void => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        cb.current(...args);
      }, timeoutMs);
    },
    [timeoutMs]
  );
}
