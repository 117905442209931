import classNames from 'classnames';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import sharedStyles from './Configurator.shared.module.less';
import { FooterButtons } from './FooterButtons';
import {
  CalendarSelectOption,
  CALENDAR_VIEW_OPTIONS,
  CALENDAR_VIEW_WEEKLY,
  ConfiguratorFormData,
  MAX_MONTH_RANGE,
  MONTH_OPTIONS,
  ORIENTATION_OPTIONS,
  YEAR_OPTIONS,
} from './form';
import { StepComponentProps } from './types';

type VariantProps = StepComponentProps;

export function Variant({ onBack, onNext }: VariantProps) {
  const form = useFormContext<ConfiguratorFormData>();
  const startMonth = useController({
    control: form.control,
    name: 'startMonth',
    defaultValue: '1',
    rules: {
      validate: (value) => {
        const numValue = parseInt(value || '0');
        if (numValue < 1) {
          return false;
        }
        if (form.getValues('startYear') === form.getValues('endYear')) {
          const endMonth = parseInt(form.getValues('endMonth') || '12');
          if (numValue > endMonth) {
            form.setValue('endMonth', String(numValue));
          }
        }
      },
    },
  });
  const startYear = useController({
    control: form.control,
    name: 'startYear',
    defaultValue: String(new Date().getFullYear()),
    rules: {
      validate: (value) => {
        const numValue = parseInt(value || '0');
        if (numValue < new Date().getFullYear()) {
          return false;
        }
        const endYearValue = parseInt(
          form.getValues('endYear') || new Date().getFullYear().toString()
        );
        if (numValue > endYearValue) {
          form.setValue('endYear', String(numValue));
        }
      },
    },
  });
  const endMonth = useController({
    control: form.control,
    name: 'endMonth',
    defaultValue: '3',
  });
  const endYear = useController({
    control: form.control,
    name: 'endYear',
    defaultValue: String(new Date().getFullYear() + 1),
  });
  const calendarView = useController({
    control: form.control,
    name: 'calendarView',
  });
  const orientation = useController({
    control: form.control,
    name: 'orientation',
  });

  const startMonthValue = parseInt(startMonth.field.value || '1');
  const startYearValue = parseInt(startYear.field.value || String(new Date().getFullYear()));
  const endMonthValue = parseInt(endMonth.field.value || '3');
  const endYearValue = parseInt(endYear.field.value || String(new Date().getFullYear() + 1));

  const [endMonthOptions, endYearOptions] = useMemo(() => {
    return getMonthAndYearOptions(startMonthValue, startYearValue, endYearValue);
  }, [endYearValue, startMonthValue, startYearValue]);

  const isValidRange =
    !!startYearValue &&
    !!endYearValue &&
    startYearValue <= endYearValue &&
    !!startMonthValue &&
    !!endMonthValue &&
    getMonthCount(startYearValue, startMonthValue, endYearValue, endMonthValue) <= 15;
  const isValid =
    form.formState.isValid &&
    !!calendarView.field.value &&
    (calendarView.field.value !== CALENDAR_VIEW_WEEKLY || !!orientation.field.value) &&
    isValidRange;

  return (
    <>
      <h1 className={sharedStyles.configurator__subtitle}>Step 2: View &amp; Orientation</h1>

      <h2 className={sharedStyles.configurator__label}>Calendar Range</h2>
      <div className="grid grid-cols-[minmax(0,_1fr)_30px_minmax(0,_1fr)] gap-2 items-center">
        <div className="flex flex-row gap-2 items-center justify-end">
          <select className={sharedStyles.configurator__numberInput} {...startMonth.field}>
            {MONTH_OPTIONS.map(({ label, value, disabled }) => (
              <option key={value} value={value} disabled={disabled}>
                {label}
              </option>
            ))}
          </select>
          <select className={sharedStyles.configurator__numberInput} {...startYear.field}>
            {YEAR_OPTIONS.map(({ label, value, disabled }) => (
              <option key={value} value={value} disabled={disabled}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <span className="font-light text-sm text-center">to</span>
        <div className="flex flex-row gap-2 items-center justify-start">
          <select className={sharedStyles.configurator__numberInput} {...endMonth.field}>
            {endMonthOptions.map(({ label, value, disabled }) => (
              <option key={value} value={value} disabled={disabled}>
                {label}
              </option>
            ))}
          </select>
          <select className={sharedStyles.configurator__numberInput} {...endYear.field}>
            {endYearOptions.map(({ label, value, disabled }) => (
              <option key={value} value={value} disabled={disabled}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classNames(sharedStyles.configurator__help, 'text-center')}>
        <div className={classNames(!isValidRange && 'text-red-500')}>
          Please note that you can only select a range of up to 15 months.
        </div>
        <div>Previews on the next pages will not represent your selected date range.</div>
      </div>

      <h2 className={sharedStyles.configurator__label}>Calendar View</h2>
      <div className="grid grid-cols-2 gap-4">
        {CALENDAR_VIEW_OPTIONS.map(({ name, description, value }) => (
          <label
            key={value}
            className={classNames(
              sharedStyles.configurator__option,
              calendarView.field.value === value && 'selected'
            )}
          >
            <div>{name}</div>
            <div className={classNames(sharedStyles.configurator__help, 'flex-grow')}>
              {description}
            </div>
            <input type="radio" {...calendarView.field} value={value} />
          </label>
        ))}
      </div>

      {calendarView.field.value === CALENDAR_VIEW_WEEKLY && (
        <>
          <h2 className={sharedStyles.configurator__label}>Orientation</h2>
          <div className="grid grid-cols-2 gap-4">
            {ORIENTATION_OPTIONS.map(({ name, description, value }) => (
              <label
                key={value}
                className={classNames(
                  sharedStyles.configurator__option,
                  orientation.field.value === value && 'selected'
                )}
              >
                <div>{name}</div>
                <div className={classNames(sharedStyles.configurator__help, 'flex-grow')}>
                  {description}
                </div>
                <input type="radio" {...orientation.field} value={value} />
              </label>
            ))}
          </div>
        </>
      )}

      <FooterButtons nextEnabled={isValid} onBack={onBack} onNext={onNext} />
    </>
  );
}

function getMonthCount(
  startYear: number,
  startMonth: number,
  endYear: number,
  endMonth: number
): number {
  if (startMonth < 1 || startMonth > 12 || endMonth < 1 || endMonth > 12) {
    return 99;
  }
  return (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
}

function getMonthAndYearOptions(
  startMonth: number,
  startYear: number,
  endYear: number
): [CalendarSelectOption[], CalendarSelectOption[]] {
  const monthsInCurrentYear = 12 - startMonth + 1;
  const remainingMonths = MAX_MONTH_RANGE - monthsInCurrentYear;
  const maxEndYear = startYear + Math.ceil(remainingMonths / 12);
  const maxEndMonth = remainingMonths % 12 || 12;

  let monthOptions: CalendarSelectOption[] = [];
  if (endYear < maxEndYear) {
    monthOptions = MONTH_OPTIONS.map((option) => ({
      ...option,
      disabled: startYear === endYear && option.value < startMonth,
    }));
  } else if (endYear === maxEndYear) {
    monthOptions = MONTH_OPTIONS.map((option) => {
      if (option.value > maxEndMonth) {
        return { ...option, disabled: true };
      }
      return option;
    });
  } else {
    monthOptions = MONTH_OPTIONS.map((option) => ({ ...option, disabled: true }));
  }

  const yearOptions: CalendarSelectOption[] = YEAR_OPTIONS.map((option) => {
    if (option.value < startYear || option.value > maxEndYear) {
      return { ...option, disabled: true };
    }
    return option;
  });

  return [monthOptions, yearOptions];
}
