import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import 'tailwindcss/tailwind.css';
import './index.less';

import { Cancel, Success } from 'checkout';
import { Imprint } from 'Imprint';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Configurator } from './configurator';
import { Home } from './Home';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/configure/*" element={<Configurator />} />
      <Route path="/success" element={<Success />} />
      <Route path="/cancel" element={<Cancel />} />
      <Route path="/imprint" element={<Imprint />} />
      <Route path="*" element={<Home />} />
    </Route>
  )
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />

        <div className="flex flex-col items-center text-sm text-stone-500 my-16">
          <p>Copyright &copy; {new Date().getFullYear()} Build a Planner</p>
          <p>
            <a className="cursor-pointer text-sky-600" href="/imprint">
              Imprint
            </a>
          </p>
        </div>
      </QueryClientProvider>
    </StrictMode>
  );
}
