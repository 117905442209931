import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import sharedStyles from './Configurator.shared.module.less';
import { FooterButtons } from './FooterButtons';
import {
  ConfiguratorFormData,
  getCalendarViewOption,
  getLocaleOption,
  getOrientationOption,
  getPageSizeOption,
  MONTH_LABELS,
} from './form';
import { StepComponentProps } from './types';

interface FinalizeProps extends Omit<StepComponentProps, 'onNext'> {
  nextEnabled: boolean;
}

export function Finalize({ onBack, nextEnabled }: FinalizeProps) {
  const form = useFormContext<ConfiguratorFormData>();
  const formData = form.getValues() as Required<ConfiguratorFormData>;

  const hasAnyAddons =
    !!formData.addons.notesSheets ||
    formData.addons.yearOverview ||
    formData.addons.compactMonthlyTodos ||
    formData.addons.extendedMonthlyTodos;

  return (
    <>
      <h1 className={sharedStyles.configurator__subtitle}>Step 5: Finalize</h1>

      <div className="flex flex-col items-center gap-4 mt-8">
        <div className="text-center">
          <span className="font-medium">You are almost done!</span>
          <br />
          <span className="text-stone-500">
            Please review your configuration and proceed to checkout.
          </span>
        </div>

        <table className={classNames('text-sm', sharedStyles.configurator__table)}>
          <tbody>
            <tr>
              <th className="font-bold">Format:</th>
              <td>{getPageSizeOption(formData.pageSize)?.name}</td>
            </tr>
            <tr>
              <th className="font-bold">Language:</th>
              <td>{getLocaleOption(formData.locale)?.name}</td>
            </tr>
            <tr>
              <th className="font-bold">Time Range:</th>
              <td>
                {MONTH_LABELS[parseInt(formData.startMonth || '1') - 1]}&nbsp;
                {formData.startYear} - {MONTH_LABELS[parseInt(formData.endMonth || '12') - 1]}&nbsp;
                {formData.endYear}
              </td>
            </tr>
            <tr>
              <th className="font-bold">View:</th>
              <td>
                {getCalendarViewOption(formData.calendarView)?.name}
                {formData.calendarView === 'weekly' && (
                  <>&nbsp;({getOrientationOption(formData.orientation)?.name})</>
                )}
              </td>
            </tr>
            <tr>
              <th className="font-bold">Options:</th>
              <td>
                <ul className="list-disc list-inside">
                  <li>
                    {formData.layout.showCalendarWeek ? 'Show calendar week' : 'No calendar week'}
                  </li>
                  <li>{formData.layout.showWeekDates ? 'Show week dates' : 'No week dates'}</li>
                  <li>{formData.layout.weeklyColumns === 'three' ? '3 columns' : '4 columns'}</li>
                  <li>
                    {formData.layout.showAllDay ? 'Include all day section' : 'No all day section'}
                  </li>
                  <li>{formData.layout.showNotes ? 'Include notes area' : 'No notes area'}</li>
                  <li>
                    {formData.layout.showBoxes ? 'Draw borders around elements' : 'No borders'}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th className="font-bold">Addons:</th>
              <td>
                {hasAnyAddons ? (
                  <ul className="list-disc list-inside">
                    {!!formData.addons.notesSheets && (
                      <li>{formData.addons.notesSheets} sheets with notes pages</li>
                    )}
                    {formData.addons.yearOverview && <li>Year overview</li>}
                    {formData.addons.compactMonthlyTodos && <li>Compact monthly to-dos</li>}
                    {formData.addons.extendedMonthlyTodos && <li>Extended monthly to-dos</li>}
                  </ul>
                ) : (
                  <span className="italic">None</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <FooterButtons
        nextEnabled={nextEnabled}
        onBack={onBack}
        onNext="submit"
        nextLabel="Checkout"
        nextClassName="plausible-event-name=Checkout"
      />
    </>
  );
}
