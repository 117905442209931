import { useCancelOrder } from 'hooks/useCancelOrder';
import { useEffect, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';

export function Cancel() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('order_id');

  const cancelOrder = useCancelOrder();

  const cancelled = useRef(false);
  useEffect(() => {
    if (orderId && !cancelled.current) {
      cancelled.current = true;
      cancelOrder.mutateAsync(orderId);
    }
  }, [cancelOrder, orderId]);

  return (
    <div className="flex flex-col items-center">
      <header className="flex flex-col items-center justify-end text-center h-[50vh] gap-8 mb-8">
        <Logo className="h-16 w-auto" title="Build a Planner" />

        <h1 className="font-extrabold text-4xl uppercase">Oh no... 😞</h1>

        <p className="text-stone-500">
          The checkout process has been cancelled.
          <br />
          We hope to see you again soon!
        </p>
      </header>

      <Link to="/configure" className="cursor-pointer text-large btn cta">
        👉 Start new Planner
      </Link>
    </div>
  );
}
