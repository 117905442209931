import classNames from 'classnames';
import { CalendarConfig } from 'hooks/types';
import { useCalendarPreview } from 'hooks/useCalendarPreview';
import { useCheckout } from 'hooks/useCheckout';
import { useDebounced } from 'hooks/useDebounced';
import { useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { Addons } from './Addons';
import { Finalize } from './Finalize';
import { ConfiguratorFormData, toCalendarConfig } from './form';
import { GeneralSettings } from './GeneralSettings';
import { Layout } from './Layout';
import { Variant } from './Variant';

const NUM_STEPS = 5;

export function Configurator() {
  const navigate = useNavigate();

  const form = useForm<ConfiguratorFormData>({
    mode: 'onChange',
    defaultValues: {
      step: 1,
    },
  });
  const step = useWatch({
    control: form.control,
    name: 'step',
  });

  const [previewParams, setPreviewParams] = useState<CalendarConfig>();
  const updatePreviewParams = useDebounced((formData: ConfiguratorFormData) => {
    setPreviewParams(toCalendarConfig(formData));
  }, 200);
  const preview = useCalendarPreview(previewParams);

  const checkout = useCheckout();
  const onSubmit = useCallback(
    (formData: ConfiguratorFormData): void => {
      const calendarConfig = toCalendarConfig(formData);
      checkout.mutateAsync(calendarConfig).then((response) => {
        window.location.href = response.checkoutUrl;
      });
    },
    [checkout]
  );

  const onBack = useCallback((): void => {
    if (step === 1) {
      navigate('/');
    } else {
      const nextStep = step - 1;
      form.setValue('step', nextStep);
      navigate('/configure/' + nextStep);
    }
  }, [form, navigate, step]);

  const onNext = useCallback((): void => {
    const nextStep = step + 1;
    form.setValue('step', nextStep);
    navigate('/configure/' + nextStep);
  }, [form, navigate, step]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center px-4 py-8">
          <Logo className="flex shrink-0 h-12 w-auto mb-8" />
          <div className="flex flex-col items-center self-stretch grow">
            <div
              className={classNames(
                'rounded-2xl shadow-lg bg-white p-8 w-full',
                step !== 3 && step !== 4 ? 'md:w-[600px]' : 'md:max-w-[1000px]'
              )}
            >
              <h1 className="text-center text-xl font-medium mb-4">🎨 Customize</h1>

              <div className="relative h-2 w-full mb-2">
                <div className="absolute w-full h-2 bg-stone-300 rounded-full"></div>
                <div
                  className="absolute h-2 bg-gradient-to-r from-indigo-400 to-sky-600 rounded-full transition-all"
                  style={{ width: `${(step * 100) / NUM_STEPS}%` }}
                ></div>
              </div>

              {step === 1 && <GeneralSettings onBack={onBack} onNext={onNext} />}
              {step === 2 && <Variant onBack={onBack} onNext={onNext} />}
              {step === 3 && (
                <Layout
                  onBack={onBack}
                  onNext={onNext}
                  previewLoading={preview.isFetching}
                  preview={preview.data}
                  onUpdatePreview={updatePreviewParams}
                />
              )}
              {step === 4 && (
                <Addons
                  onBack={onBack}
                  onNext={onNext}
                  previewLoading={preview.isFetching}
                  preview={preview.data}
                />
              )}
              {step === 5 && <Finalize onBack={onBack} nextEnabled={!checkout.isLoading} />}
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
