import classNames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import sharedStyles from './Configurator.shared.module.less';
import { FooterButtons } from './FooterButtons';
import { ConfiguratorFormData, LOCALE_OPTIONS, PAGE_SIZE_OPTIONS } from './form';
import { StepComponentProps } from './types';

type GeneralSettingsProps = StepComponentProps;

export function GeneralSettings({ onBack, onNext }: GeneralSettingsProps) {
  const form = useFormContext<ConfiguratorFormData>();
  const pageSize = useController({
    control: form.control,
    name: 'pageSize',
  });
  const locale = useController({
    control: form.control,
    name: 'locale',
  });

  return (
    <>
      <h1 className={sharedStyles.configurator__subtitle}>Step 1: General Settings</h1>

      <h2 className={sharedStyles.configurator__label}>Page Size</h2>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <div className="hidden sm:block"></div>
        {PAGE_SIZE_OPTIONS.map(({ name, value }) => (
          <label
            key={value}
            className={classNames(
              sharedStyles.configurator__option,
              pageSize.field.value === value && 'selected'
            )}
          >
            <input type="radio" {...pageSize.field} value={value} />
            <span>{name}</span>
          </label>
        ))}
        <div className="hidden sm:block"></div>
      </div>

      <h2 className={sharedStyles.configurator__label}>Language</h2>
      <div className="grid gap-4">
        {LOCALE_OPTIONS.map(({ name, description, value }) => (
          <label
            key={value}
            className={classNames(
              sharedStyles.configurator__option,
              locale.field.value === value && 'selected'
            )}
          >
            <div>{name}</div>
            <div className={sharedStyles.configurator__help}>{description}</div>
            <input type="radio" {...locale.field} value={value} />
          </label>
        ))}
      </div>

      <FooterButtons
        nextEnabled={!!pageSize.field.value && !!locale.field.value}
        onBack={onBack}
        onNext={onNext}
      />
    </>
  );
}
