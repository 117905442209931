import { useMutation } from 'react-query';
import { BASE_URL } from './constants';

export function useGenerateCalendar() {
  return useMutation(fetchGenerateCalendar);
}

async function fetchGenerateCalendar(checkoutSessionId: string): Promise<Blob> {
  const response = await fetch(`${BASE_URL}/scripting/generate.php`, {
    method: 'POST',
    body: JSON.stringify({
      checkoutSessionId,
    }),
  });

  if (!response.ok) {
    console.error('failed to generate calendar', response.status, await response.text());
    throw new Error('failed to generate calendar');
  }

  return await response.blob();
}
