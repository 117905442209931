import { Switch } from '@headlessui/react';
import { ForwardedRef, forwardRef } from 'react';

interface ToggleProps {
  name: string;
  label?: string;
  value?: boolean;
  onChange(value: boolean): void;
}

const toggleRef = forwardRef(function Toggle(
  { name, label, value, onChange }: ToggleProps,
  ref: ForwardedRef<HTMLLabelElement>
) {
  return (
    <label
      ref={ref}
      htmlFor={name}
      className="flex flex-row flex-nowrap items-center gap-2 cursor-pointer"
    >
      <Switch
        id={name}
        checked={value}
        onChange={onChange}
        className={`${
          value ? 'bg-indigo-600' : 'bg-stone-200'
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">{label}</span>
        <span
          className={`${
            value ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
      <span>{label}</span>
    </label>
  );
});
export const Toggle = toggleRef;
