/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { Toggle } from 'components/Toggle';
import { CalendarPreview } from 'hooks/useCalendarPreview';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import sharedStyles from './Configurator.shared.module.less';
import { FooterButtons } from './FooterButtons';
import { ConfiguratorFormData } from './form';
import { StepComponentProps } from './types';

interface AddonProps extends StepComponentProps {
  previewLoading: boolean;
  preview?: CalendarPreview;
}

export function Addons({ onBack, onNext, previewLoading, preview }: AddonProps) {
  const form = useFormContext<ConfiguratorFormData>();

  const [previewAddon, setPreviewAddon] = useState<keyof CalendarPreview>('notes');
  const previewImgSrc = preview?.[previewAddon];

  const notesSheets = useController({
    control: form.control,
    name: 'addons.notesSheets',
    defaultValue: 10,
    rules: {
      min: 0,
      max: 20,
    },
  });
  const yearOverview = useController({
    control: form.control,
    name: 'addons.yearOverview',
    defaultValue: false,
  });
  const compactMonthlyTodos = useController({
    control: form.control,
    name: 'addons.compactMonthlyTodos',
    defaultValue: false,
  });
  const extendedMonthlyTodos = useController({
    control: form.control,
    name: 'addons.extendedMonthlyTodos',
    defaultValue: false,
  });

  return (
    <>
      <h1 className={sharedStyles.configurator__subtitle}>Step 4: Addons</h1>

      <div className="flex flex-row gap-4 flex-wrap-reverse lg:flex-nowrap mt-8">
        <div className="flex flex-col min-w-[300px] grow lg:grow-0">
          <h2 className={classNames(sharedStyles.configurator__label, 'nomargin self-start')}>
            Addons
          </h2>

          <div className="flex flex-col gap-2 mt-4">
            <label
              className={classNames(
                sharedStyles.configurator__option,
                'option--row',
                previewAddon === 'notes' && 'selected'
              )}
              onClick={() => setPreviewAddon('notes')}
            >
              <div className="flex flex-col items-start grow">
                <div>Notes</div>
                <div className={classNames(sharedStyles.configurator__help, 'text-left')}>
                  Pages with dotted background for taking notes.
                  <br />
                  Enter the number of sheets (2 pages per sheet).
                </div>
              </div>
              <input
                type="number"
                step={1}
                min={0}
                max={20}
                {...notesSheets.field}
                onChange={(e) => notesSheets.field.onChange(parseInt(e.target.value, 10) || 0)}
                className={classNames(sharedStyles.configurator__numberInput, 'w-14 ml-3')}
              />
            </label>

            <div
              className={classNames(
                sharedStyles.configurator__option,
                'option--row',
                previewAddon === 'yearOverview' && 'selected'
              )}
              onClick={() => setPreviewAddon('yearOverview')}
            >
              <div className="flex flex-col items-start grow">
                <div>Year Overview</div>
                <div className={classNames(sharedStyles.configurator__help, 'text-left')}>
                  A single page with an overview of all months.
                </div>
              </div>
              <Toggle {...yearOverview.field} />
            </div>

            <div
              className={classNames(
                sharedStyles.configurator__option,
                'option--row',
                previewAddon === 'compactMonthlyTodos' && 'selected'
              )}
              onClick={() => setPreviewAddon('compactMonthlyTodos')}
            >
              <div className="flex flex-col items-start grow">
                <div>Monthly To-Dos (compact)</div>
                <div className={classNames(sharedStyles.configurator__help, 'text-left')}>
                  3 months per page with a to-do list.
                </div>
              </div>
              <Toggle {...compactMonthlyTodos.field} />
            </div>

            <div
              className={classNames(
                sharedStyles.configurator__option,
                'option--row',
                previewAddon === 'extendedMonthlyTodos' && 'selected'
              )}
              onClick={() => setPreviewAddon('extendedMonthlyTodos')}
            >
              <div className="flex flex-col items-start grow">
                <div>Monthly To-Dos (extended)</div>
                <div className={classNames(sharedStyles.configurator__help, 'text-left')}>
                  One page for every month with a to-do list.
                </div>
              </div>
              <Toggle {...extendedMonthlyTodos.field} />
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 sm:min-w-[400px]">
          <h2 className={classNames(sharedStyles.configurator__label, 'nomargin self-start')}>
            Preview
          </h2>
          <div className="bg-stone-100 p-4 mt-4 rounded-lg flex flex-col items-center relative min-h-[50px]">
            {previewLoading && (
              <div className="flex justify-center items-center absolute top-2 right-2">
                <span className="h-8 w-8 block rounded-full border-solid border-4 border-stone-400 border-t-stone-600 animate-spin"></span>
              </div>
            )}
            {!!preview && <img className="max-h-[600px]" src={previewImgSrc} alt="Preview" />}
          </div>
        </div>
      </div>

      <FooterButtons nextEnabled={form.formState.isValid} onBack={onBack} onNext={onNext} />
    </>
  );
}
