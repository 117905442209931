import { useMutation } from 'react-query';
import { BASE_URL } from './constants';

export function useCancelOrder() {
  return useMutation(fetchCancelOrder);
}

async function fetchCancelOrder(orderId: string): Promise<void> {
  const response = await fetch(`${BASE_URL}/scripting/cancel.php`, {
    method: 'POST',
    body: JSON.stringify({
      orderId,
    }),
  });

  if (!response.ok) {
    console.error('failed to cancel order', response.status, await response.text());
    throw new Error('failed to cancel order');
  }
}
