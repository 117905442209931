import { CalendarConfig } from 'hooks/types';

const PAGE_SIZE_A4 = 'A4';
const PAGE_SIZE_A5 = 'A5';
const PAGE_SIZE_US_LETTER = 'US_LETTER';
const PAGE_SIZE_US_HALF_LETTER = 'US_HALF_LETTER';
type PageSize =
  | typeof PAGE_SIZE_A4
  | typeof PAGE_SIZE_A5
  | typeof PAGE_SIZE_US_LETTER
  | typeof PAGE_SIZE_US_HALF_LETTER;

export const PAGE_SIZE_OPTIONS: { name: string; value: PageSize }[] = [
  // { name: 'A4', value: PAGE_SIZE_A4 },
  { name: 'A4 / A5', value: PAGE_SIZE_A5 },
  // { name: 'US Letter', value: PAGE_SIZE_US_LETTER },
  { name: 'US Letter / Half Letter', value: PAGE_SIZE_US_HALF_LETTER },
];

export function getPageSizeOption(pageSize: PageSize) {
  return PAGE_SIZE_OPTIONS.find((option) => option.value === pageSize);
}

export const LOCALE_US = 'en_US';
const LOCALE_UK = 'en_GB';
const LOCALE_DE = 'de_DE';
type Locale = typeof LOCALE_US | typeof LOCALE_UK | typeof LOCALE_DE;

export const LOCALE_OPTIONS: { name: string; description: string; value: Locale }[] = [
  {
    name: '🇺🇸 English (US)',
    description: 'Sunday start of week, date format MM/DD/YYYY',
    value: LOCALE_US,
  },
  {
    name: '🇬🇧 English (UK)',
    description: 'Monday start of the week, date format DD/MM/YYYY',
    value: LOCALE_UK,
  },
  {
    name: '🇩🇪 German',
    description: 'Monday start of the week, date format DD.MM.YYYY',
    value: LOCALE_DE,
  },
];

export function getLocaleOption(locale: Locale) {
  return LOCALE_OPTIONS.find((option) => option.value === locale);
}

export const CALENDAR_VIEW_WEEKLY = 'weekly';
const CALENDAR_VIEW_DAILY = 'daily';
type CalendarView = typeof CALENDAR_VIEW_WEEKLY | typeof CALENDAR_VIEW_DAILY;

export const CALENDAR_VIEW_OPTIONS: { name: string; description: string; value: CalendarView }[] = [
  {
    name: '🗓️ Weekly',
    description: 'One week spread across left and right page.',
    value: CALENDAR_VIEW_WEEKLY,
  },
  { name: '📅 Daily', description: 'Every day is on its own page.', value: CALENDAR_VIEW_DAILY },
];

export function getCalendarViewOption(calendarView: CalendarView) {
  return CALENDAR_VIEW_OPTIONS.find((option) => option.value === calendarView);
}

export const CALENDAR_ORIENTATION_COLUMN = 'columns';
const CALENDAR_ORIENTATION_ROW = 'rows';
type CalendarOrientation = typeof CALENDAR_ORIENTATION_COLUMN | typeof CALENDAR_ORIENTATION_ROW;

export const ORIENTATION_OPTIONS: {
  name: string;
  description: string;
  value: CalendarOrientation;
}[] = [
  {
    name: '⬇️ Columns',
    description: 'Every day is a column.',
    value: CALENDAR_ORIENTATION_COLUMN,
  },
  {
    name: '➡️ Rows',
    description: 'Every day is a row.',
    value: CALENDAR_ORIENTATION_ROW,
  },
];

export function getOrientationOption(orientation: CalendarOrientation) {
  return ORIENTATION_OPTIONS.find((option) => option.value === orientation);
}

export interface CalendarSelectOption {
  label: string;
  value: number;
  disabled?: boolean;
}

export const MAX_MONTH_RANGE = 15;
export const YEAR_OPTIONS: CalendarSelectOption[] = [...new Array(3)].map((_, i) => ({
  value: new Date().getFullYear() + i,
  label: `${new Date().getFullYear() + i}`,
}));
export const MONTH_LABELS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTH_OPTIONS: CalendarSelectOption[] = [...new Array(12)].map((_, i) => ({
  value: i + 1,
  label: MONTH_LABELS[i],
}));

export interface ConfiguratorFormData {
  step: number;
  pageSize?: typeof PAGE_SIZE_A5 | typeof PAGE_SIZE_US_HALF_LETTER;
  locale?: Locale;

  startMonth?: string;
  startYear?: string;
  endMonth?: string;
  endYear?: string;

  calendarView?: CalendarView;
  orientation?: CalendarOrientation;

  layout?: LayoutFormData;
  addons?: AddonsFormData;
}

interface LayoutFormData {
  showWeekDates?: boolean;
  showCalendarWeek?: boolean;
  weeklyColumns?: 'three' | 'four';
  showBoxes?: boolean;
  showAllDay?: boolean;
  showNotes?: boolean;
  dottedBg?: boolean;
}

interface AddonsFormData {
  notesSheets?: number;
  yearOverview?: boolean;
  compactMonthlyTodos?: boolean;
  extendedMonthlyTodos?: boolean;
}

export function toCalendarConfig(formData?: Omit<ConfiguratorFormData, 'step'>): CalendarConfig {
  const currentYear = new Date().getFullYear();
  const startYear = parseInt(formData?.startYear || '0') || currentYear;
  const startMonth = parseInt(formData?.startMonth || '1') || 1;
  const endYear = parseInt(formData?.endYear || '0') || currentYear;
  const endMonth = parseInt(formData?.endMonth || '1') || 12;
  return {
    pageSize: formData?.pageSize || PAGE_SIZE_A5,
    locale: formData?.locale || LOCALE_US,
    startDate: getDateString(startYear, startMonth, 'start'),
    endDate: getDateString(endYear, endMonth, 'end'),
    showCalendarWeek: formData?.layout?.showCalendarWeek ?? true,
    showWeekDates: formData?.layout?.showWeekDates ?? true,
    calendarView: formData?.calendarView ?? 'weekly',
    weeklyColumns: formData?.layout?.weeklyColumns ?? 'three',
    orientation: formData?.orientation ?? 'columns',
    showBoxes: formData?.layout?.showBoxes ?? false,
    showAllDay: formData?.layout?.showAllDay ?? true,
    showNotes: formData?.layout?.showNotes ?? true,
    dottedBg: formData?.layout?.dottedBg ?? true,
    notesSheets: formData?.addons?.notesSheets ?? 10,
    yearOverview: formData?.addons?.yearOverview ?? false,
    compactMonthlyTodos: formData?.addons?.compactMonthlyTodos ?? false,
    extendedMonthlyTodos: formData?.addons?.extendedMonthlyTodos ?? false,
  };
}

function getDateString(year: number, month: number, type: 'start' | 'end') {
  const date = new Date(year, type === 'start' ? month - 1 : month, type === 'start' ? 1 : 0);
  return `${year}-${format2DigitNumber(month)}-${format2DigitNumber(date.getDate())}`;
}
function format2DigitNumber(number: number) {
  return number.toString().padStart(2, '0');
}
