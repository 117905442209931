import classNames from 'classnames';

interface FooterButtonsProps {
  nextEnabled: boolean;
  nextLabel?: string;
  nextClassName?: string;
  onBack: () => void;
  onNext: (() => void) | 'submit';
}

export function FooterButtons({
  nextEnabled,
  nextLabel,
  nextClassName,
  onBack,
  onNext,
}: FooterButtonsProps) {
  return (
    <div className="mt-12 flex flex-row justify-between">
      <button onClick={onBack} className="w-32 btn">
        Back
      </button>
      <button
        disabled={!nextEnabled}
        className={classNames('w-32 btn cta', nextClassName)}
        onClick={typeof onNext === 'function' ? onNext : undefined}
        type={typeof onNext === 'function' ? 'button' : onNext}
      >
        {nextLabel || 'Next'}
      </button>
    </div>
  );
}
