import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as Calendar } from './assets/undraw-calendar.svg';
import { ReactComponent as Book } from './assets/undraw-book.svg';
import { ReactComponent as Files } from './assets/undraw-files.svg';
import adobeReaderPrinter from './assets/adobe-reader-printer.png';
import adobeReaderBooklet from './assets/adobe-reader-booklet.png';

export function Home() {
  return (
    <>
      <header className="flex flex-col items-center justify-end text-center my-16 sm:h-[50vh] gap-6">
        <Logo className="h-24 sm:h-36 w-auto" title="Build a Planner" />
        <p className="text-stone-500">
          Your customized planner.
          <br />
          Configure and download now in just 5 steps.
        </p>
        <Link to="/configure" className="cursor-pointer text-lg btn cta">
          👉 Get my own Planner
        </Link>
        <p className="font-extrabold text-xl text-cta mb-14 text-center">
          Just <span className="">3.99&euro;</span> for any configuration.
          <br />
          Downloadable PDF printout.
        </p>
      </header>

      <main className="mx-auto flex flex-col gap-16 items-center text-stone-800 px-4 pt-4 pb-8">
        <div className="flex flex-col md:flex-row items-center gap-8 bg-white shadow-lg rounded-xl p-8 text-stone-500 max-w-[600px]">
          <Book className="shrink-0 w-32 h-auto" title="Planner" />
          <div className="flex flex-col gap-2">
            <h2 className="text-stone-900 font-medium text-xl">
              A planner that meet&apos;s your needs
            </h2>
            <p>Looking for a planner that&apos;s tailored to your specific preferences?</p>
            <p>
              Choose from a variety of paper sizes, layouts, and options to create your perfect
              planner.
            </p>
            <p className="font-medium text-cta">
              In it&apos;s minimal configuration perfect for some additional bullet journaling!
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row-reverse items-center gap-8 bg-white shadow-lg rounded-xl p-8 text-stone-500 max-w-[600px]">
          <Calendar className="shrink-0 w-32 h-auto" title="Schedule" />
          <div className="flex flex-col gap-2">
            <h2 className="text-stone-900 font-medium text-xl">Customized Schedule</h2>
            <p>Easily customize your schedule&apos;s appearance.</p>
            <p>
              A background for easier writing - and maybe a notes area or an all day section for
              each day?
            </p>
            <p>Just check the configuration preview to see how it will look!</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-8 bg-white shadow-lg rounded-xl p-8 text-stone-500 max-w-[600px]">
          <Files className="shrink-0 w-32 h-auto" title="Addons" />
          <div className="flex flex-col gap-2">
            <h2 className="text-stone-900 font-medium text-xl">Expand with Addons</h2>
            <p>
              Choose if you want some extras like notes pages in your style or yearly and monthly
              overviews with todo lists.
            </p>
            <p>You can always go with only what you need.</p>
          </div>
        </div>

        <div className="flex flex-col gap-3 items-center">
          <span className="text-stone-500">Try out creating your uniquely tailored planner.</span>
          <Link to="/configure" className="cursor-pointer text-lg btn cta">
            👉 Get my own Planner
          </Link>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-8 bg-white shadow-lg rounded-xl p-8 text-stone-500 max-w-[600px]">
          <div className="flex flex-col gap-2">
            <h2 className="text-center font-bold text-2xl text-cta">How to print</h2>

            <p className="mt-4 font-bold">Instructions for A4 / Letter:</p>
            <p>
              This is the easy page format - just print it on both sides as you would any other
              document but{' '}
              <span className="font-extrabold">
                make sure to select the option to fill the page
              </span>
              .
            </p>
            <p>
              If you feel unsure about the result just print a single page from the middle of the
              document to test it out.
            </p>

            <p className="mt-4 font-bold">Instructions for A5 / Half Letter:</p>
            <p>
              This is the difficult part when you need to print on A4 / Letter paper - but
              that&apos;s why we&apos;re here to help!
              <br />
              We strongly recommend to use Adobe Acrobat Reader for printing as any standard
              printing dialog will not work reliably in our experience.
            </p>
            <ul className="list-decimal list-inside">
              <li className="my-2">
                Download and install{' '}
                <a
                  className="cursor-pointer text-sky-600"
                  href="https://get.adobe.com/reader/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Adobe Acrobat Reader
                </a>
                .
              </li>
              <li className="my-2">Open your planner PDF with Adobe Acrobat Reader.</li>
              <li className="my-2">
                Click the printer symbol in the toolbar at the top of the document.
                <img
                  src={adobeReaderPrinter}
                  alt="Adobe Reader Printer Toolbar"
                  className="mx-auto my-2 w-44"
                />
              </li>
              <li className="my-2">
                In the print dialog make sure to select the{' '}
                <span className="font-extrabold">Booklet</span> option.
                <img
                  src={adobeReaderBooklet}
                  alt="Adobe Reader Booklet Option"
                  className="mx-auto my-2 w-[90%]"
                />
              </li>
              <li className="my-2">
                All other settings should be left as they are - you&apos;re good to print!
              </li>
            </ul>
          </div>
        </div>
      </main>
    </>
  );
}
